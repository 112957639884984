import IconAvailability from '../../Icons/IconAvailability';
// import IconCryosauna from '../../Icons/IconCryosauna';
import IconMassage from '../../Icons/IconMassage';
import IconCoach from '../../Icons/IconCoach';
import IconPrice from '../../Icons/IconPrice';
// import IconSauna from '../../Icons/IconSauna';

function About() {
  return (
    <section className="about container" id="about">
      <h2>КТО МЫ&nbsp;И&nbsp;ПОЧЕМУ НАС ЛЮБЯТ!</h2>
      <p>
        Hammer&nbsp;&mdash; молодой, энергичный, динамично развивающийся
        спортивный клуб.
      </p>
      <p>
        Наша цель&nbsp;&mdash; популяризация здорового и&nbsp;спортивного образа
        жизни.
      </p>
      <p>
        Мы&nbsp;смотрим в&nbsp;будущее, стараемся отслеживать эффективные
        и&nbsp;перспективные методики, тренажёры, спортивное питание
        и&nbsp;предлагаем нашим клиентам самое эффективное и&nbsp;проверенное
        на&nbsp;себе. В&nbsp;нашем клубе максимально удобные условия пребывания
        и&nbsp;уютная обстановка. Мы&nbsp;стараемся обеспечить всё необходимое
        для достижения поставленных целей наших клиентов. Мы&nbsp;знаем, как
        добиться результатов и&nbsp;поможем Вам.
      </p>
      <ul className="about__features-list" style={{ display: 'flex', justifyContent: 'center' }}>
        <li className="about__features-item">
          <IconPrice />
          <p>Доступные цены</p>
        </li>
        <li className="about__features-item">
          <IconCoach />
          <p>Сильнейший тренерский состав</p>
        </li>
        <li className="about__features-item">
          <IconAvailability />
          <p>Залы в шаговой доступности</p>
        </li>
        {/* <li className="about__features-item">
          <IconSauna />
          <p>Сауна</p>
        </li> */}
        <li className="about__features-item" style={{ marginRight: 0 }}>
          <IconMassage />
          <p>Массаж</p>
        </li>
        {/* <li className="about__features-item">
          <IconCryosauna w={84} h={84} />
          <p>Криосауна</p>
        </li> */}
      </ul>
    </section>
  );
}

export default About;
