import { useState } from 'react';

import Seo from '../components/Seo';
import Header from '../components/Header';
import ServiceGym from '../components/ServicesTariffs/ServiceGym';
import Footer from '../components/Footer';
import PopupOffer from '../components/PopupOffer';

function ServiceGymPage({ gymData, offerGymData }) {
  const [openPopup, setOpenPopup] = useState(false);

  // функция открытия
  const handleOpenPopup = () => setOpenPopup(true);

  // функция закрытия
  const closePopup = () => setOpenPopup(false);

  return (
    <div className="page__body">
      <Seo
        title="Спортивный клуб Hammer | Абонементы на персональные тренировки и тренажерный зал в Самаре"
        description="Спортивный клуб Hammer предоставляет широкий выбор абонементов: персональные тренировки, персональные тренировки для двоих, самостоятельные тренировки, разовое посещение спортивного зала, студенческие абонементы."
        keywords="тренажерный зал, фитнес-клуб в самаре, индивидульный тренер, персональная тренировка"
      />
      <Header />
      <ServiceGym
        data={gymData}
        offerData={offerGymData}
        handleOpenOfferPopup={handleOpenPopup}
      />
      <Footer />
      <PopupOffer
        opened={openPopup}
        close={closePopup}
        popupState={openPopup}
        offerData={offerGymData}
      />
    </div>
  );
}

export default ServiceGymPage;
