import { Helmet } from 'react-helmet-async';

import favicon from '../../favicon.ico';
import iconSvg from '../../icon.svg';
import icon180 from '../../img/icons/180.png';
import icon192 from '../../img/icons/192.png';
import icon256 from '../../img/icons/256.png';
import icon512 from '../../img/icons/512.png';

import manifest from '../../manifest.json';

export default function Seo({ title, description, keywords }) {
  return (
    <Helmet
      // htmlAttributes={{
      //   lang: 'ru',
      // }}
      title={title || `Спортивный клуб Hammer | Тренажерный зал, персональные тренировки, танцы и
        фитнес-направления, сауна, массаж`}
      meta={[
        // {
        //   charset: 'UTF-8',
        // },
        // {
        //   name: 'viewport',
        //   content: 'width=device-width, initial-scale=1.0',
        // },
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        {
          property: 'og:title',
          content: title || `Спортивный клуб Hammer | Тренажерный зал, персональные тренировки, танцы и
          фитнес-направления, сауна, массаж`,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ]}
      link={[
        {
          rel: 'icon',
          href: `${favicon}`,
        },
        {
          rel: 'icon',
          type: 'image/svg+xml',
          href: `${iconSvg}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: `${icon180}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: `${icon192}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: `${icon256}`,
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: `${icon512}`,
        },
        {
          rel: 'manifest',
          href: `${manifest}`,
        },
      ]}
    />
  );
}
