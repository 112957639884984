/* eslint-disable max-len */
import { useContext } from 'react';

import { AppContext } from '../../../context/AppContext';

import fakeArray from '../../../utils/fakeArray';

import TitleWrapper from '../../TitleWrapper';
import TariffWrapper from '../TariffWrapper';
import TariffItem from '../TariffItem';
import TariffOffer from '../TariffOffer';

function ServiceGym({ data, offerData, handleOpenOfferPopup }) {
  const { isLoadingService } = useContext(AppContext);

  return (
    <main className="main">
      <TitleWrapper
        title="ТРЕНАЖЕРНЫЙ ЗАЛ"
        link="/#services"
      >
        <p>
          Наш тренажерный зал оснащен всем необходимым для занятий оборудованием,
          современными разнообразными тренажерами. Ключевым преимуществом тренажерного
          зала является возможность построить фигуру мечты и сохранить ее надолго
        </p>
      </TitleWrapper>
      <section className="grid personal container">
        {/* Проходимся по массиву данных, распределяем данные по компонентам */}
        { isLoadingService ? (
          <TariffWrapper>
            {fakeArray.map((member) => <TariffItem key={member.id} />)}
          </TariffWrapper>
        )
          : data?.map((item, index) => {
          // Находим первый ключ в объекте, потому что он один в expand
            const expandKey = Object.keys(item.expand)[0];
            // Так же распределяем инфу, только из expand
            return (
              <div key={index}>
                {
                  (index === 1 && offerData.length !== 0) && (
                    <TariffOffer
                      title="СПЕЦИАЛЬНОЕ ПРЕДЛОЖЕНИЕ!!!"
                      handleOpenOfferPopup={handleOpenOfferPopup}
                    >
                      <p>При покупке данного пакета, абонемент в тренажерный зал предоставляется в подарок!</p>
                    </TariffOffer>
                  )
                }
                <TariffWrapper key={item.id} title={item.name_tariff}>
                  {
                    item.expand[expandKey].map((tariff) => (
                      <TariffItem
                        key={tariff.id}
                        text={tariff.text}
                        sale={tariff.sale}
                        offer={tariff.offer}
                      />
                    ))
                  }
                </TariffWrapper>
              </div>
            );
          })}
      </section>
    </main>
  );
}

export default ServiceGym;
