import { useState } from 'react';
// import { Helmet } from 'react-helmet-async';

import Seo from '../components/Seo';
import Header from '../components/Header';
import Main from '../components/Main';
import Footer from '../components/Footer';
import PopupSchedule from '../components/PopupSchedule';

function MainPage({ sliderData }) {
  const [openPopup, setOpenPopup] = useState(false); // стейт переключения состояния модалки

  // функция открытия
  const handleOpenPopup = () => setOpenPopup(true);

  // функция закрытия
  const closePopup = () => setOpenPopup(false);

  return (
    <div className="page__body page__body--index">
      <Seo
        title="Спортивный клуб Hammer | Тренажерный зал, персональные тренировки, танцы и фитнес-направления, сауна, массаж"
        description="Спортивный клуб Hammer - современный
        тренажерный зал 🏋️‍♂️ в шаговой доступности в Самаре."
      />
      <Header />
      <Main openPopup={handleOpenPopup} sliderData={sliderData} />
      <Footer />
      <PopupSchedule
        opened={openPopup}
        close={closePopup}
      />
    </div>
  );
}

export default MainPage;
