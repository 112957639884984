import { useState, useContext } from 'react';
import DOMPurify from 'dompurify';
import ContentLoader from 'react-content-loader';

import { AppContext } from '../../../context/AppContext';
import IconToggle from '../../Icons/IconToggle';

function ScheduleItem({ dayName, dayList }) {
  const [toggleButton, setToggleButton] = useState(false);
  const { isLoadingSchedule } = useContext(AppContext);

  // ручка переключения расписания
  function handleToggleButton() {
    setToggleButton(!toggleButton);
  }

  return (
    isLoadingSchedule ? (
      <ContentLoader
        className="schedule__title"
        speed={2}
        width="100%"
        height="100%"
        opacity={0.6}
        viewBox="0 0 800 65"
        backgroundColor="#432545"
        foregroundColor="#6b1f70"
      >
        <rect x="139" y="264" rx="0" ry="0" width="1" height="1" />
        <rect x="1" y="0" rx="0" ry="0" width="100%" height="100%" />
      </ContentLoader>
    ) : (
      <>
        <button
          className={`schedule__title ${toggleButton ? 'schedule__title--show' : ''}`}
          onClick={handleToggleButton}
        >
          <IconToggle />
          <h2>{dayName}</h2>
        </button>
        {
          // если получаем пустой массив, отображаем что ничего нет
          !dayList ? (
            <ul className="schedule__list">
              <li className="schedule__item">
                <p>Нет расписания</p>
              </li>
            </ul>
          )
            : (
              <div
                className="schedule__innerContainer"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dayList) }}
              />
            )
        }
      </>
    )
  );
}

export default ScheduleItem;
