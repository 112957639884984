import Swiper from '../Swiper';

// props -openPopup
function Services({ sliderData }) {
  return (
    <section className="services" id="services">
      <div className="container">
        <h2>УСЛУГИ И ТАРИФЫ</h2>
        <Swiper sliderData={sliderData} />
      </div>
      {/* <div
        id="schedule-list"
        className="services__schedule services__schedule--js"
      >
        <div className="services__schedule-wrapper-js">
          <p>ПОСМОТРЕТЬ РАСПИСАНИЕ НА ГРУППОВЫЕ ЗАНЯТИЯ</p>
          <button
            className="button button--services"
            type="button"
            onClick={openPopup}
          >
            Посмотреть расписание
          </button>
        </div>
      </div> */}
    </section>
  );
}

export default Services;
