import { useState, useContext } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { AppContext } from '../../context/AppContext';

import imageLogo from '../../img/sprite-icons/icon-logo.svg';

function Header() {
  const [burgerMenu, setBurgerMenu] = useState(false); // стейт открытия бургер меню
  const location = useLocation().pathname;
  const { contactsData } = useContext(AppContext);

  // функция открытия меню
  const openBurgerMenu = () => {
    setBurgerMenu(true);
  };

  // функция закрытия меню
  const closeBurgerMenu = () => {
    setBurgerMenu(false);
  };

  // ручка плавной прокрутки к якорю
  const handleScrollOnAnchor = (el) => {
    // проверка на открытое меню
    if (burgerMenu) {
      closeBurgerMenu();
    }

    // behavior - режим прокрутки, block: скролл к началу/концу блока
    el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <header className="header container">
      <NavLink to="/" className="header__logo">
        <img className="header__logo-img" src={imageLogo} width={87} height={42} alt="Логотип Hammer" />
      </NavLink>
      <nav className={`header__nav ${burgerMenu ? 'header__nav--show' : ''}`}>
        <div className="header__nav-wrapper header__nav-wrapper--nojs">
          <ul className="header__nav-list">
            <li className="header__nav-item">
              <HashLink
                to="/#about"
                scroll={handleScrollOnAnchor}
              >
                Почему мы?
              </HashLink>
            </li>
            <li
              className={`
                header__nav-item
                ${location === '/gym' || location === '/fighting-art'
                || location === '/dance' || location === '/massage'
                ? 'header__nav-item--active' : ''
                }
              `}
            >
              <HashLink
                to="/#services"
                scroll={handleScrollOnAnchor}
              >
                Услуги и тарифы
              </HashLink>
            </li>
            {/* <li
              className={`
              header__nav-item
              ${location === '/schedule-fight' || location === '/schedule-dance'
                ? 'header__nav-item--active' : ''}
              `}
            >
              <HashLink
                to="/#schedule-list"
                scroll={handleScrollOnAnchor}
              >
                Расписание
              </HashLink>
            </li> */}
            <li
              className={`
                header__nav-item 
                ${location === '/team' ? 'header__nav-item--active' : ''}
                `}
            >
              <HashLink
                to="/#team"
                scroll={handleScrollOnAnchor}
              >
                Наша команда
              </HashLink>
            </li>
            <li className="header__nav-item">
              <HashLink
                to="#contact"
                scroll={handleScrollOnAnchor}
              >
                Контакты
              </HashLink>
            </li>
          </ul>
          <button
            type="button"
            className={`
              button button--nojs
              ${burgerMenu ? 'button--close' : 'button--burger'}
            `}
            onClick={burgerMenu ? closeBurgerMenu : openBurgerMenu}
          >
            <span />
          </button>
          <a className="header__tel" href="tel:+79939914101">
            { contactsData[0]?.contact || '+7 (993) 991-41-01' }
          </a>
        </div>
      </nav>
      <div className={`overlay ${burgerMenu ? 'overlay_active' : ''}`} />
    </header>
  );
}

export default Header;
