import React from 'react';
import { createRoot } from 'react-dom/client';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import './styles/index.scss';
import App from './components/App';

const rootElement = document.getElementById('root');
const helmetContext = {};

const app = (
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement);
} else {
  createRoot(rootElement).render(app);
}
