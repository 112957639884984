import { useContext } from 'react';

import { AppContext } from '../../context/AppContext';

import IconVk from '../Icons/IconVk';
import IconTelegram from '../Icons/IconTelegram';

// Компонент на всю длинну экрана
function Footer() {
  const { contactsData } = useContext(AppContext);

  return (
    <footer className="footer" id="contact">
      <div className="container">
        <div className="footer__wrapper">
          <div className="footer__suggestions">
            <p>Вопросы, отзывы и предложения</p>
            <a href="tel:+79276058036">{contactsData[5]?.contact}</a>
            <a href="tel:+79297005323">{contactsData[6]?.contact}</a>
            <a href="mailto:info@hammer63.ru">info@hammer63.ru</a>
          </div>
          <div className="footer__contacts">
            <div className="footer__contact-1">
              <a href="https://yandex.ru/maps/-/CCU57KqZPA">ул. Революционная, д. 126</a>
            </div>
            <div className="footer__contact-2">
              <a href="https://yandex.ru/maps/-/CCUByQQ6OC">ул. Советской Армии, д. 107А</a>
            </div>
            <div className="footer__contact-3">
              <a href="https://yandex.ru/maps/-/CCUByQUHHD">пр-т Кирова 104</a>
            </div>
          </div>
          <ul className="footer__social-list">
            <li className="footer__social-item footer__social-item--vk">
              <a href={contactsData[3]?.contact}>
                <IconVk />
              </a>
            </li>
            <li className="footer__social-item footer__social-item--tg">
              <a href={contactsData[4]?.contact}>
                <IconTelegram />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
