import { HashLink } from 'react-router-hash-link';

// компонент заголовка
function TitleWrapper({ title, link, children }) {
  return (
    <section className="container" style={{ position: 'relative' }}>
      <HashLink
        className="button button--back"
        to={link}
        smooth
      >
        <span />
      </HashLink>
      <div className="main__wrapper">
        <h1>{title}</h1>
        {children}
      </div>
    </section>
  );
}

export default TitleWrapper;
