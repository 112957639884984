function IconToggle() {
  return (
    <svg
      width={38}
      height={26}
      role="img"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17 20L.546.5h32.909L17 20z" fill="#C7E80A" />
    </svg>
  );
}

export default IconToggle;
