import { useContext } from 'react';

import { AppContext } from '../../../context/AppContext';

import introLogo from '../../../img/icons/intro-logo.png';
import modelIntro from '../../../img/img-intro-model.webp';
import modelIntro2x from '../../../img/img-intro-model@2x.webp';
import imgIntro from '../../../img/img-intro-model.png';
import imgIntro2x from '../../../img/img-intro-model@2x.png';

// import IconSolarium from '../../Icons/IconSolarium';
// import IconCryosauna from '../../Icons/IconCryosauna';
// import IconHamam from '../../Icons/IconHamam';

function Intro() {
  const { contactsData } = useContext(AppContext);

  return (
    <section className="intro container">
      <div className="intro__wrapper">
        <div className="intro__content">
          <img
            loading="lazy"
            src={introLogo}
            height={49}
            width={337}
            alt="Название спортивного клуба"
          />
          <p className="intro__desc">ТРЕНАЖЕРНЫЙ ЗАЛ У ДОМА</p>
          <p className="intro__offer">
            ПРИ ПОКУПКЕ ПАКЕТА ПЕРСОНАЛЬНЫХ ТРЕНИРОВОК, АБОНЕМЕНТ В ТРЕНАЖЕРНЫЙ
            ЗАЛ В ПОДАРОК!
          </p>
          <a
            href={contactsData[2]?.contact}
            target="_blank"
            className="button"
            rel="noreferrer"
          >
            Вопросы сюда
          </a>
          {/* <a
            href={contactsData[1]?.contact}
            target="_blank"
            className="button intro__bot"
            rel="noreferrer"
          >
            Телеграм бот
          </a> */}
          {/* <ul className="intro__list">
            <li className="intro__item">
              <IconSolarium className="intro__icons" />
              <a href="massage">Солярий</a>
            </li>
            <li className="intro__item">
              <IconCryosauna className="intro__icons" w={35} h={35} />
              <a href="massage">Криосауна</a>
            </li>
            <li className="intro__item">
              <IconHamam className="intro__icons" />
              <a href="massage">Хамам</a>
            </li>
          </ul> */}
        </div>
      </div>
      <div className="intro__img">
        <picture>
          <source
            type="image/webp"
            srcSet={`${modelIntro} 1x, ${modelIntro2x} 2x`}
          />
          <img
            loading="lazy"
            src={imgIntro}
            srcSet={`${imgIntro2x} 2x`}
            width="635"
            height="713"
            alt="Спортивная девушка"
          />
        </picture>
      </div>
    </section>
  );
}

export default Intro;
