import { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import {
//   Navigation, Scrollbar, A11y,
// } from 'swiper';

import { AppContext } from '../../../context/AppContext';
import fakeArray from '../../../utils/fakeArray';

// Компонент слайда
import Slide from './Slide';

// import IconArrow from '../../Icons/IconArrow';

const Slider = ({ sliderData }) => {
  // рефы кнопок
  // const navigationPrevRef = useRef(null);
  // const navigationNextRef = useRef(null);

  const { isLoadingSlider } = useContext(AppContext);

  // конфиг слайдера
  const params = {
    spaceBetween: 30,
    slidesPerView: 2,
    // определения кастомных кнопок
    // navigation: {
    //   nextEl: '.services__btn',
    //   prevEl: '.services__btn',
    // },
    // брейкпонты свайпера
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 23,
      },
      1024: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 31,
      },
    },
  };

  return (
    <Swiper
      className="services__wrapper services__wrapper--nojs swiper"
      // получаем весь конфиг
      {...params}
      // modules={[Navigation, Scrollbar, A11y]}
      // navigation
      // pagination={{ clickable: true }}
      // scrollbar={{ draggable: true }}
      // определитель рефов
      // onBeforeInit={
      //   (swiper) => {
      //     swiper.params.navigation.prevEl = navigationPrevRef.current;
      //     swiper.params.navigation.nextEl = navigationNextRef.current;
      //   }
      // }
    >
      {/* <button
        className="services__btn services__btn--nojs services__btn--prev"
        type="button"
        ref={navigationPrevRef}
      >
        <IconArrow />
      </button>
      <button
        className="services__btn services__btn--nojs services__btn--next"
        type="button"
        ref={navigationNextRef}
      >
        <IconArrow />
      </button> */}
      <div className="services__wrapper services__wrapper--nojs swiper">
        <div className="services__list swiper-wrapper">
          {
            isLoadingSlider ? fakeArray.map((fake) => (
              <SwiperSlide key={fake.id}>
                <Slide />
              </SwiperSlide>
            ))
              : sliderData?.map((slide) => (
                <SwiperSlide key={slide.id} className="services__item swiper-slide">
                  <Slide
                    webp1x={slide.webp1}
                    webp2x={slide.webp2}
                    img1x={slide.img1}
                    img2x={slide.img2}
                    alt={slide.name}
                    text={slide.name}
                    href={slide.href}
                  />
                </SwiperSlide>
              ))
          }
        </div>
      </div>
    </Swiper>
  );
};

export default Slider;
