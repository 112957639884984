function TariffOffer({ title, handleOpenOfferPopup, children }) {
  return (
    <section className="offer container">
      <div className="offer__wrapper">
        <h2>{title}</h2>
        {children}
        <button
          className="button button--promo"
          onClick={handleOpenOfferPopup}
        >
          Подробнее об акции
        </button>
      </div>
    </section>
  );
}

export default TariffOffer;
