function IconTelegram() {
  return (
    <svg
      width={26}
      height={26}
      role="img"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#fff"
        d="M24.687 30.163c-.402 0-.843-.132-1.32-.395l-10.803-7.976a.502.502 0 01-.038-.774L25.322 9.503a.499.499 0 11.669.743l-12.34 11.106 10.254 7.576c.271.145.729.329 1.055.178.332-.152.496-.637.576-1.017l4.375-20.617c.17-.681.088-1.229-.215-1.481-.231-.192-.575-.206-.947-.038L3.033 15.871c-.789.317-1.037.643-1.033.751.003.071.173.267.68.422l6.375 1.99 6.999-4.416a.5.5 0 01.533.845l-7.194 4.539a.504.504 0 01-.416.055l-6.592-2.058c-1.17-.358-1.37-.99-1.384-1.335-.02-.473.253-1.157 1.667-1.723l25.695-9.908c.697-.318 1.445-.25 1.974.191.613.511.813 1.413.549 2.474l-4.371 20.598c-.184.878-.566 1.457-1.137 1.719a1.63 1.63 0 01-.691.148z"
      />
      <path
        fill="#fff"
        d="M24.687 30.163c-.402 0-.843-.132-1.32-.395l-6.72-4.959a.5.5 0 11.594-.804l6.664 4.923c.271.145.729.329 1.055.178.332-.152.496-.637.576-1.017l4.375-20.617c.17-.681.088-1.229-.215-1.481-.231-.192-.575-.206-.947-.038L3.033 15.871c-.789.317-1.037.643-1.033.751.003.071.173.267.68.422l6.594 2.058a.503.503 0 01.33.332l2.267 7.461c.061.167.108.333.149.477.032.114.072.256.099.298-.004-.005.076.019.289.02.447 0 .617-.167.957-.498l1.66-1.614a.5.5 0 01.697.717L14.09 27.88c-.402.393-.828.809-1.682.809-.98 0-1.178-.434-1.351-1.046-.035-.124-.075-.267-.135-.432l-2.198-7.233-6.339-1.979c-1.17-.358-1.37-.99-1.384-1.335-.02-.473.253-1.157 1.667-1.723l25.695-9.908c.697-.318 1.445-.25 1.974.191.613.511.813 1.413.549 2.474l-4.371 20.598c-.184.878-.566 1.457-1.137 1.719a1.63 1.63 0 01-.691.148z"
      />
      <path
        fill="#fff"
        d="M18.5 14c0 .275-.225.5-.5.5a.501.501 0 01-.5-.5c0-.275.225-.5.5-.5s.5.225.5.5z"
      />
    </svg>
  );
}

export default IconTelegram;
