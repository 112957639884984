import { NavLink } from 'react-router-dom';

// media
import modelTeam from '../../../img/img-coaches.webp';
import modelTeam2x from '../../../img/img-coaches@2x.webp';

// images
import imageTeam from '../../../img/img-coaches.png';
import imageTeam2x from '../../../img/img-coaches@2x.png';

function Team() {
  return (
    <section className="team container" id="team">
      <div className="team__about">
        <h2>НАША КОМАНДА</h2>
        <p>
          Клуб развился из маленького подвальчика, качалки «для своих», где
          готовились к соревнованиям фанаты железного спорта.
        </p>
        <p>
          С&nbsp;основания в&nbsp;00-х поддерживали и&nbsp;развивали клуб
          Дмитрий Растренин и&nbsp;Денис Горлов. Увлечённые спортом и&nbsp;идеей
          сохранить домашнюю и&nbsp;дружелюбную атмосферу, сделать
          не&nbsp;просто помещение с&nbsp;тренажерами, а&nbsp;реализовать идеи
          тренеров и&nbsp;спортсменов о&nbsp;комфортной и&nbsp;удобной среде.
          Клубе, где люди улыбаются и&nbsp;жмут руки, поддерживают
          и&nbsp;мотивируют друг друга.
        </p>
        <NavLink
          to="/team"
          className="button button--team"
        >
          Посмотреть нашу команду
        </NavLink>
      </div>
      <div className="team__coaches">
        <picture>
          <source
            type="image/webp"
            srcSet={`${modelTeam} 1x, ${modelTeam2x} 2x`}
          />
          <img
            loading="lazy"
            src={imageTeam}
            srcSet={`${imageTeam2x} 2x`}
            width={533}
            height={607}
            alt="Наша команда тренеров"
          />
        </picture>
      </div>
    </section>
  );
}

export default Team;
