import { useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';

function PopupMember({
  memberData,
  popupState,
  close,
}) {
  const onClickOutsideRef = useRef();

  // слушатель закрытия модалки по esc
  useEffect(() => {
    // проверяем открыта ли модалка, если происходит событие по esc, закрываем
    const onCloseToEsc = (e) => popupState && e.key === 'Escape' && close();

    window.addEventListener('keyup', onCloseToEsc);
    return () => window.removeEventListener('keyup', onCloseToEsc);
  }, [close, popupState]);

  // слушатель закрытия модалки по заднему слою
  useEffect(() => {
    // проверяем открыта ли модалка, если происходит за пределами ее, закрываем
    const onClickOutside = (e) => popupState
    && !onClickOutsideRef.current.contains(e.target)
    && close();

    document.addEventListener('mouseup', onClickOutside);
    return () => document.removeEventListener('mouseup', onClickOutside);
  }, [close, popupState]);

  return (
    <section className={`popup popup__member overlay ${popupState ? 'overlay_active' : ''}`}>
      <div
        className="popup__member-wrapper"
        ref={onClickOutsideRef}
      >
        <button
          className="button button--close"
          aria-label="Закрыть"
          onClick={close}
        />
        <div className="popup__member-container">
          <img
            className="popup__member-images"
            src={memberData.img1}
            alt={memberData.post}
            width={278}
            height={309}
          />
          <div className="popup__member-block">
            <h2 className="popup__member-name">
              {memberData.name}
            </h2>
            <div
              className="popup__info"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(memberData.popupInfo) }}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PopupMember;
