import Seo from '../components/Seo';
import Header from '../components/Header';
import ScheduleFight from '../components/Schedule/ScheduleFight';
import Footer from '../components/Footer';

function ScheduleFightPage({ scheduleFightData }) {
  return (
    <div className="page__body">
      <Seo
        title="Спортивный клуб Hammer | Расписание занятий по боевым видам спорта"
        description="Секции единоборств 🥊 в Самаре. В данном разделе можно узнать актуальное расписание занятий по боевым видам спорта в спортивном клубе Hammer."
        keywords="секции боевых искусств в Самаре, тренер по боксу, секции единоборств в Самаре"
      />
      <Header />
      <ScheduleFight scheduleFightData={scheduleFightData} />
      <Footer />
    </div>
  );
}

export default ScheduleFightPage;
