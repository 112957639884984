import PocketBase from 'pocketbase';

const BASE_URL = 'https://hammer-samara.pockethost.io';
// const LOCAL_URL = 'http://127.0.0.1:8090';

const api = new PocketBase(BASE_URL);

// отключение автоотмены запроса
api.autoCancellation(false);

export default api;
