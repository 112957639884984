import { useState, useEffect, useContext } from 'react';
import ContentLoader from 'react-content-loader';

import { AppContext } from '../../../context/AppContext';
import { tabWidth, mobileWidth } from '../../../utils/resize';
import {
  memberLoader,
  loaderSettingDesk,
  loaderSettingTab,
  loaderSettingMobile,
} from './loader';

function TeamMember({
  dataMamber,
  openPopup,
}) {
  // ширина экрана
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);
  // ширина лоадера
  const [widthLoader, setWidthLoader] = useState(0);
  // высота лоадера
  const [heightLoader, setHeightLoader] = useState(0);
  // настройка стиля лоадера
  const [settingLoader, setSettingLoader] = useState({});

  const { isLoadingTrainers } = useContext(AppContext);

  // функция изменения экрана
  const resizeWindow = () => setWidthWindow(window.innerWidth);

  // обработчик изменения экрана
  useEffect(() => {
    window.addEventListener('resize', resizeWindow);

    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  // обработчик переключения размера лоадера
  useEffect(() => {
    if (widthWindow <= mobileWidth) {
      setWidthLoader(memberLoader.mobileWidth);
      setHeightLoader(memberLoader.mobileHeight);
      setSettingLoader(loaderSettingMobile);
    } else if (widthWindow <= tabWidth) {
      setWidthLoader(memberLoader.tabWidth);
      setHeightLoader(memberLoader.tabHeight);
      setSettingLoader(loaderSettingTab);
    } else {
      setWidthLoader(memberLoader.desktopWidth);
      setHeightLoader(memberLoader.desktopHeigth);
      setSettingLoader(loaderSettingDesk);
    }
  }, [widthWindow]);

  return (
    // данные приходящие с pocketbase
    // name, post, popupInfo, webp1, webp2, img1, img2
    <li className="our-team__item">
      {
        isLoadingTrainers ? (
          <ContentLoader
            title="Загрузка..."
            speed={2}
            width={widthLoader}
            height={heightLoader}
            opacity={0.6}
            gradientRatio={1.2}
            viewBox={`0 0 ${widthLoader} ${heightLoader}`}
            backgroundColor="#432545"
            foregroundColor="#6b1f70"
          >
            <circle
              cx={settingLoader?.circle?.cx}
              cy={settingLoader?.circle?.cy}
              r={settingLoader?.circle?.r}
            />
            <rect x="139" y="264" rx="0" ry="0" width="1" height="1" />
            <rect
              x={settingLoader?.text?.x}
              y={settingLoader?.text?.y}
              rx={settingLoader?.text?.rx}
              ry={settingLoader?.text?.ry}
              width={settingLoader?.text?.width}
              height={settingLoader?.text?.height}
            />
            <rect
              x={settingLoader?.text?.x}
              y={settingLoader?.text2?.y}
              rx={settingLoader?.text?.rx}
              ry={settingLoader?.text?.ry}
              width={settingLoader?.text?.width}
              height={settingLoader?.text?.height}
            />
            <rect
              x={settingLoader?.button?.x}
              y={settingLoader?.button?.y}
              rx={settingLoader?.button?.rx}
              ry={settingLoader?.button?.ry}
              width={settingLoader?.button?.width}
              height={settingLoader?.button?.height}
            />
          </ContentLoader>
        )
          : (
            <>
              <picture>
                <source
                  type="image/webp"
                  srcSet={`${dataMamber.webp1} 1x, ${dataMamber.webp2} 2x`}
                />
                <img
                  loading="lazy"
                  src={dataMamber.img1}
                  srcSet={`${dataMamber.img2} 2x`}
                  width={278}
                  height={309}
                  alt={dataMamber.post}
                />
              </picture>
              <p className="our-team__item-name">{dataMamber.name}</p>
              <p className="our-team__item-desc">{dataMamber.post}</p>
              {
              // если информации нет о человеке, кнопку не отображаем
              dataMamber.popupInfo && (
              <button
                className="button button--more"
                onClick={() => openPopup(dataMamber)}
                type="button"
              >
                Подробнее
              </button>
              )
            }
            </>
          )
      }
    </li>
  );
}

export default TeamMember;
