function IconPrice() {
  return (
    <svg
      viewBox="0 0 84 84"
      width={84}
      height={84}
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84 42c0 23.196-18.804 42-42 42S0 65.196 0 42 18.804 0 42 0s42 18.804 42 42z"
        fill="#C7E80A"
      />
      <rect
        x="37.836"
        y="20.31"
        width="46.717"
        height="27.955"
        rx="4"
        transform="rotate(67.825 37.836 20.31)"
        fill="#2D3807"
      />
      <path
        stroke="#C7E80A"
        strokeWidth="2"
        strokeLinecap="square"
        d="M16.901 29.828l17.35 41.807"
      />
      <path
        d="M75 46c0 9.363-7.809 17-17.5 17C47.809 63 40 55.363 40 46s7.809-17 17.5-17C67.191 29 75 36.637 75 46z"
        fill="#C7E80A"
        stroke="#2D3807"
        strokeWidth="2"
      />
      <path
        d="M61.376 51.802h-6.08v2.954l-.243.244H53.1l-.245-.244v-2.954h-1.013l-.244-.244v-1.465l.244-.244h1.014v-2.222h-1.014l-.244-.244v-1.465l.244-.244h1.014v-8.496l.244-.244c.374-.041.773-.074 1.196-.098a32.76 32.76 0 011.294-.073 70.765 70.765 0 012.563-.05c.928 0 1.77.094 2.527.282.765.179 1.42.48 1.965.903.554.423.981.985 1.282 1.684.301.692.452 1.55.452 2.576v.244c0 1.001-.163 1.84-.488 2.515a4.067 4.067 0 01-1.343 1.611c-.578.4-1.274.684-2.088.855-.813.163-1.709.244-2.685.244h-2.478v2.222h6.079l.244.244v1.465l-.244.244zm-3.711-12.89c-.17 0-.362.003-.574.011-.203 0-.41.008-.622.025a7.7 7.7 0 00-.623.049 4.66 4.66 0 00-.55.073v6.604h2.26c.666 0 1.269-.033 1.806-.098.545-.073 1.009-.224 1.391-.452a2.31 2.31 0 00.88-.988c.203-.432.304-1.01.304-1.734v-.244c0-.667-.11-1.212-.33-1.636a2.472 2.472 0 00-.902-.988 3.49 3.49 0 00-1.355-.489 9.955 9.955 0 00-1.685-.134z"
        fill="#2D3807"
      />
      <path
        stroke="#2D3807"
        strokeWidth="2"
        strokeLinecap="round"
        d="M21.379 72.52l-.991-1.914m-3.402-6.967l-.799-2.002m-2.744-8.332l-.799-2.002M9.56 44.068l-.798-2.002m-.992-8.584l.33-2.13m3.263-7.154l1.842-1.119m8.154-4.607l2.005-.792m8.033-2.383l2.076-.579m8.124 1.292l1.466 1.58m3.503 6.614l.759 2.017m19.025-.591l1.95.918m-12.729-1.754l2.109-.442m16.984 6.087l1.221 1.836m3.26 7.916l.356 2.177"
      />
    </svg>
  );
}

export default IconPrice;
