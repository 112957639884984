import { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import { AppContext } from '../../../../context/AppContext';
import { sliderLoaderSize, sliderDesktopSetting, sliderMobileSetting } from './loader';

const Slide = ({
  webp1x,
  webp2x,
  img1x,
  img2x,
  alt,
  text,
  href,
}) => {
  // ширина экрана
  const [widthWindow, setWidthWindow] = useState(window.innerWidth);
  // ширина лоадера
  const [widthLoader, setWidthLoader] = useState(0);
  // высота лоадера
  const [heightLoader, setHeightLoader] = useState(0);
  // стейт настроек лоадера
  const [settingLoader, setSettingLoader] = useState({});

  const { isLoadingSlider } = useContext(AppContext);

  // функция изменения экрана
  const resizeWindow = () => setWidthWindow(window.innerWidth);

  // обработчик изменения экрана
  useEffect(() => {
    window.addEventListener('resize', resizeWindow);

    return () => {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  // обработчик переключения размера лоадера
  useEffect(() => {
    if (widthWindow <= 330) {
      setWidthLoader(sliderLoaderSize.mobileWidth);
      setHeightLoader(sliderLoaderSize.mobileHeight);
      setSettingLoader(sliderMobileSetting);
    } else {
      setWidthLoader(sliderLoaderSize.desktopWidth);
      setHeightLoader(sliderLoaderSize.desktopHeight);
      setSettingLoader(sliderDesktopSetting);
    }
  }, [widthWindow]);

  return (
    isLoadingSlider
      ? (
        <ContentLoader
          speed={2}
          width={widthLoader}
          height={heightLoader}
          opacity={0.6}
          viewBox={`0 0 ${widthLoader} ${heightLoader}`}
          backgroundColor="#432545"
          foregroundColor="#6b1f70"
        >
          <rect x="139" y="264" rx="0" ry="0" width="1" height="1" />
          <circle
            cx={settingLoader?.circle?.cx}
            cy={settingLoader?.circle?.cy}
            r={settingLoader?.circle?.r}
          />
          <rect
            x={settingLoader?.text?.x}
            y={settingLoader?.text?.y}
            rx={settingLoader?.text?.rx}
            ry={settingLoader?.text?.ry}
            width={settingLoader?.text?.width}
            height={settingLoader?.text?.height}
          />
        </ContentLoader>
      ) : (
        <NavLink to={href}>
          <picture>
            <source
              type="image/webp"
              srcSet={`${webp1x} 1x, ${webp2x} 2x`}
            />
            <img
              loading="lazy"
              src={img1x}
              srcSet={`${img2x} 2x`}
              width={257}
              height={296}
              alt={alt}
            />
          </picture>
          <p>{text}</p>
        </NavLink>
      )
  );
};

export default Slide;
