function IconCoach() {
  return (
    <svg
      viewBox="0 0 84 84"
      width={84}
      height={84}
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84 42c0 23.196-18.804 42-42 42S0 65.196 0 42 18.804 0 42 0s42 18.804 42 42z"
        fill="#C7E80A"
      />
      <path
        d="M21 68l-3-2m7.285-34.564L23 32.686m-8.484 26.631l-1.032-2.633M12 48v-3m18.824 25.488L28 70.335M15 38l2-1m24-27h2m-12.707 3.708l1.412-1.416M28 22.981l.275-1.98m2.432 7.81l-1.142-1.641M38 71.188l3.996-.189m10.668-2.145L50 69.808"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M52.36 16.1c.484.779.659 4.727.638 5.279-.02.552-8.97 3.72-10.925 3.619-1.955-.102-5.689-.876-6.025-1.542-.337-.666 1.19-7.902 1.988-8.32.798-.417 13.839.186 14.323.964z"
        fill="#2D3807"
        stroke="#2D3807"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M53 12l2 1m12.148 24.906l.323 2.81M64.887 28l.907 2.679m-4.216-12.531l1.521 2.384M68 48v3m-1.715 6l-1.287 2.143M61.955 64l-2.782 1.505"
        stroke="#000"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M20.4 45.497c2.384-4.744 5.622-6.913 11.76-6.913 5.998 0 10.44 3.19 10.64 3.336-.081-.063-.843-.716-.84-3.336.004-2.77 2.03-6.685 3.369-10.848 1.34-4.164.46-7.155.46-7.155L52.526 19s1.172 8.424 1.62 11.135c.45 2.71 1.854 7.573 1.854 7.573s1.528 8.159.806 11.006c-.721 2.846-1.199 4.167-1.915 5.852-.717 1.685-.744 3.54-.744 3.54l-2.457.753s-3.694-.753-5.932-.753C43.52 58.106 34.967 62 32.16 62c-2.807 0-8.414-.204-10.83-3.894-2.417-3.69-3.317-7.864-.932-12.609z"
        fill="#2D3807"
      />
      <path
        d="M20.4 45.497c2.384-4.744 5.622-6.913 11.76-6.913 6.139 0 10.648 3.341 10.648 3.341s-.852-.573-.848-3.341c.004-2.77 2.03-6.685 3.369-10.848 1.34-4.164.46-7.155.46-7.155L52.526 19s1.172 8.424 1.62 11.135c.45 2.71 1.854 7.573 1.854 7.573s1.528 8.159.806 11.006c-.721 2.846-1.199 4.167-1.915 5.852-.717 1.685-.744 3.54-.744 3.54l-2.457.753s-3.694-.753-5.932-.753C43.52 58.106 34.967 62 32.16 62c-2.807 0-8.414-.204-10.83-3.894-2.417-3.69-3.317-7.864-.932-12.609z"
        stroke="#2D3807"
        strokeWidth="2"
      />
      <path
        d="M40.002 36c-.12 4.661 4.096 11.842 5.998 14m-21 3c3.196 2.86 11.16 2.279 14 .584M38 20l-1 5m4-5l-1 6m4.587-5.127l-1.174 5.255M48 23.458c.194.093 2.047 1.171 2.969 0C51.891 22.287 52 20 52 20m-8 6c.51-.031 1.762-1.114 2.27-1.497.509-.382.75-1.998.729-2.503"
        stroke="#C7E80A"
        strokeLinecap="round"
      />
      <path
        d="M41 26.603L37.17 26s-.85 1.611 1.256 1.954c2.106.343 2.574-1.351 2.574-1.351z"
        fill="#2D3807"
      />
    </svg>
  );
}

export default IconCoach;
