import Seo from '../components/Seo';
import Header from '../components/Header';
import ServiceMassage from '../components/ServicesTariffs/ServiceMassage';
import Footer from '../components/Footer';

function ServiceMassagePage({ massageData, otherData }) {
  return (
    <div className="page__body">
      <Seo
        title="Спортивный клуб Hammer | Аквазона, массаж, солярий"
        description="Для гостей нашего спортивного клуба Hammer у нас имеется криосауна, небольшой бассейн, солярий и массажный кабинет. Криосауна - это аппарат для оздоровления организма за счет кратковременного охлаждения (2-3 минуты) всей поверхности тела пациента потоком сухого пара азота с температурой –150° С! Очень эффективный метод оздоровления, особенно при терапии тех заболеваний, которые плохо лечатся обычными методами. Подробнее о криосауне по телефону: ☎️ 8 (846) 205-23-67"
        keywords="солярий в Самаре, криосауна в Самаре, бассейны в Самаре, сауна в Самаре"
      />
      <Header />
      <ServiceMassage massageData={massageData} offerData={otherData} />
      <Footer />
    </div>
  );
}

export default ServiceMassagePage;
