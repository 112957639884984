function IconAvailability() {
  return (
    <svg
      viewBox="0 0 84 84"
      width={84}
      height={84}
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84 42c0 23.196-18.804 42-42 42S0 65.196 0 42 18.804 0 42 0s42 18.804 42 42z"
        fill="#C7E80A"
      />
      <path
        d="M84 42c0 23.196-18.804 42-42 42S0 65.196 0 42 18.804 0 42 0s42 18.804 42 42z"
        fill="#C7E80A"
      />
      <path
        d="M22 51.822v-20.16c0-.918.782-1.662 1.747-1.662h37.506c.965 0 1.747.744 1.747 1.662v32.676c0 .918-.782 1.662-1.747 1.662H24.457"
        stroke="#2D3807"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M14 35l26.998-17.727a1.658 1.658 0 011.818-.003L70 35M16 56h21"
        stroke="#2D3807"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M14 51v11"
        stroke="#2D3807"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M10 52v9"
        stroke="#2D3807"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M7 55v4"
        stroke="#2D3807"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M42 52v9"
        stroke="#2D3807"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M45 55v4m-8-8v11"
        stroke="#2D3807"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M38 51v11"
        stroke="#2D3807"
        strokeWidth="4"
        strokeLinecap="round"
      />
      <path
        d="M12 28l-3 2m12.133-8.85l-2.262 1.7M31 15l-3 2m10.915-6.207l-2.828 1.414m9.393-.987l2.621 1.336M53.91 15.5l2.523 1.512m7.348 4.261l2.415 1.678m7.414 5.121l2.335 1.788"
        stroke="#2D3807"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconAvailability;
