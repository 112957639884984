import { useContext } from 'react';

import { AppContext } from '../../context/AppContext';

import fakeArray from '../../utils/fakeArray';
import TitleWrapper from '../TitleWrapper';
import TeamMember from './TeamMember';

function Team({ handleOpenPopup, membersData }) {
  const { isLoadingTrainers } = useContext(AppContext);

  return (
    <main className="main container">
      <TitleWrapper title="НАША КОМАНДА" link="/#team">
        <p>
          Тренерский состав клуба - профессионально занимающиеся спортсмены,
          постоянно совершенствуют свою квалификацию и
          поддерживают высокий уровень профессионализма.
          Именно эти люди обеспечивают Вам надежную поддержку и опору.
        </p>
      </TitleWrapper>
      <ul className="our-team__list">
        {
          isLoadingTrainers ? fakeArray.map((member) => <TeamMember key={member.id} />)
            : membersData?.map((trainer) => (
            // eslint-disable-next-line no-sequences
              <TeamMember
                key={trainer.id}
                dataMamber={trainer}
                openPopup={handleOpenPopup}
              />
            ))
        }
      </ul>
    </main>
  );
}

export default Team;
