import { useContext } from 'react';

import { AppContext } from '../../../context/AppContext';

import fakeArray from '../../../utils/fakeArray';

import TitleWrapper from '../../TitleWrapper';
import TariffWrapper from '../TariffWrapper';
import TariffItem from '../TariffItem';

function ServiceMassage({ massageData, offerData }) {
  const { isLoadingService } = useContext(AppContext);

  return (
    <main className="main">
      <TitleWrapper
        title="МАССАЖ"
        link="/#services"
      >
        <p>
          Расслабиться после тренировки, повысить упругость кожи и нормализовать кровообращение
          поможет массаж наших специалистов! Для дополнительной информации по стоимости и времени
          сеансов звоните по телефону
          {' '}
          <br />
          {' '}
          +7 (993) 991-41-01
        </p>
      </TitleWrapper>
      <section className="grid personal container">
        <ul className="grid__list">
          {/* Проходимся по массиву аквазона массаж, криосауна */}
          {
            !isLoadingService && massageData?.map((item) => (
              <TariffWrapper key={item.id} title={item.name_tariff} singleGrid>
                <TariffItem
                  text={item.text}
                  sale={item.sale}
                  offer={item.offer}
                />
              </TariffWrapper>
            ))
          }
        </ul>
        {/* Проходимся по массиву прочие тарифы, распределяем данные по компонентам */}
        { isLoadingService ? (
          <TariffWrapper>
            {fakeArray.map((member) => <TariffItem key={member.id} />)}
          </TariffWrapper>
        )
          : offerData?.map((item) => {
          // Находим первый ключ в объекте, потому что он один в expand
            const expandKey = Object.keys(item.expand)[0];
            // Так же распределяем инфу, только из expand
            return (
              <TariffWrapper key={item.id} title={item.name_tariff}>
                {
                item.expand[expandKey].map((tariff) => (
                  <TariffItem
                    key={tariff.id}
                    text={tariff.text}
                    sale={tariff.sale}
                    offer={tariff.offer}
                  />
                ))
              }
              </TariffWrapper>
            );
          })}
      </section>
    </main>
  );
}

export default ServiceMassage;
