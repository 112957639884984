const memberLoader = {
  desktopWidth: 290,
  desktopHeigth: 460,
  tabWidth: 190,
  tabHeight: 265,
  mobileWidth: 170,
  mobileHeight: 265,
};

const loaderSettingDesk = {
  circle: {
    cx: 141,
    cy: 151,
    r: 141,
  },
  text: {
    width: 245,
    height: 20,
    x: 20,
    y: 312,
    rx: 0,
    ry: 0,
  },
  text2: {
    y: 340,
  },
  button: {
    width: 278,
    height: 55,
    x: 5,
    y: 400,
    rx: 20,
    ry: 20,
  },
};

const loaderSettingTab = {
  circle: {
    cx: 95,
    cy: 85,
    r: 80,
  },
  text: {
    width: 150,
    height: 10,
    x: 20,
    y: 185,
    rx: 0,
    ry: 0,
  },
  text2: {
    y: 200,
  },
  button: {
    width: 120,
    height: 38,
    x: 35,
    y: 226,
    rx: 20,
    ry: 20,
  },
};

const loaderSettingMobile = {
  circle: {
    cx: 85,
    cy: 85,
    r: 80,
  },
  text: {
    width: 150,
    height: 10,
    x: 10,
    y: 185,
    rx: 0,
    ry: 0,
  },
  text2: {
    y: 200,
  },
  button: {
    width: 120,
    height: 38,
    x: 25,
    y: 226,
    rx: 20,
    ry: 20,
  },
};

export {
  memberLoader,
  loaderSettingDesk,
  loaderSettingTab,
  loaderSettingMobile,
};
