import About from './About';
import Intro from './Intro';
import Services from './Services';
import Team from './Team';

function Main({ openPopup, sliderData }) {
  return (
    <main className="main">
      <Intro />
      <About />
      <Services
        openPopup={openPopup}
        sliderData={sliderData}
      />
      <Team />
    </main>
  );
}

export default Main;
