import DOMPurify from 'dompurify';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

import { AppContext } from '../../../context/AppContext';

import IconOffer from '../../Icons/IconOffer';

function TariffItem({
  text, offer, more, sale,
}) {
  const { contactsData, isLoadingService } = useContext(AppContext);

  return (
    isLoadingService
      ? (
        <ContentLoader
          speed={2}
          title="Загрузка..."
          width={295}
          height={195}
          opacity={0.6}
          viewBox="0 0 295 195"
          backgroundColor="#432545"
          foregroundColor="#6b1f70"
        >
          <rect x="139" y="264" rx="0" ry="0" width="1" height="1" />
          <rect x="0" y="0" rx="10" ry="10" width="295" height="195" />
        </ContentLoader>
      )
      : (
        <li
          className="grid__item grid__item--other"
        >
          <div
            className="grid__inner-container"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(text) }}
          />
          {
            sale
            && (
              <Link className="button button--buy" to={contactsData[7]?.contact}>
                Приобрести
              </Link>
            )
          }
          {
            more
            && (
            <Link className="button button--buy" to={contactsData[7]?.contact}>
              Подробнее
            </Link>
            )
          }
          { offer && <IconOffer /> }
        </li>
      )
  );
}

export default TariffItem;
