import { useEffect, useState, useMemo } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AppContext } from '../../context/AppContext';

import api from '../../utils/pocketBaseApi';

import MainPage from '../../page/MainPage';
import ScheduleFightPage from '../../page/ScheduleFightPage';
import ScheduleDancePage from '../../page/ScheduleDancePage';
import ServiceGymPage from '../../page/ServiceGymPage';
// import ServiceFightPage from '../../page/ServiceFightPage';
// import ServiceDancePage from '../../page/ServiceDancePage';
import ServiceMassagePage from '../../page/ServiceMassagePage';
import TeamPage from '../../page/TeamPage';

function App() {
  // стейт контактов
  const [contactsData, setContactsData] = useState([]);
  // стейт для тренров
  const [membersData, setMembersData] = useState([]);
  // стейт расписания танцев и фитнес направления
  const [scheduleDanceData, setScheduleDanceData] = useState([]);
  // стейт расписания единоборств
  const [scheduleFightData, setScheduleFightData] = useState([]);
  // стейт слайдера
  const [sliderData, setSliderData] = useState([]);
  // стейт тарифов тренажерного зала
  const [serviceGymData, setServiceGymData] = useState([]);
  // стейт тарифов боевых искусств
  // const [serviceFightData, setServiceFightData] = useState([]);
  // стейт тарифов танцев
  // const [serviceDanceData, setServiceDanceData] = useState([]);
  // стейт аквазоны, массажа, криосауна
  const [serviceMassage, setServiceMassage] = useState([]);
  // стейт прочих тарифов
  const [serviceOtherData, setServiceOtherData] = useState([]);
  // стейт специального предложения в тренажерном зале
  const [offerGym, setOfferGym] = useState([]);
  // стейт загрузки сервисов
  const [isLoadingService, setLoadingService] = useState(false);
  // стейт загрузки тренеров
  const [isLoadingTrainers, setLoadingTrainers] = useState(false);
  // стейт загрузки расписания
  const [isLoadingSchedule, setLoadingSchedule] = useState(false);
  // стейт загрузки слайдера
  const [isLoadingSlider, setLoadingSlider] = useState(false);
  // cтейт загрузки расписпаний

  // ручка получения контактов
  const handleGetContactsData = async () => {
    try {
      const contacts = await api
        .collection('contacts')
        .getFullList();
      return setContactsData(contacts);
    } catch (error) {
      return console.log('Ошибка:', error);
    }
  };

  // ручка получения данных слайдера
  const handleGetSliderData = async () => {
    try {
      setLoadingSlider(true);
      const slider = await api
        .collection('slider_data')
        .getFullList();
      return setSliderData(slider);
    } catch (error) {
      return console.log('Ошибка:', error);
    } finally {
      setLoadingSlider(false);
    }
  };

  // ручка получения тарифов на тренажерный зал
  const handleGetGymTariffs = async () => {
    try {
      setLoadingService(true);
      const gymService = await api
        .collection('gym')
        .getFullList({ expand: 'personal, duo, abonniments' });
      return setServiceGymData(gymService);
    } catch (error) {
      return console.log('Ошибка:', error);
    } finally {
      setLoadingService(false);
    }
  };

  // ручка получения тарифов боевых искусств
  // const handleGetFightTariffs = async () => {
  //   try {
  //     setLoadingService(true);
  //     const fightService = await api
  //       .collection('fight')
  //       .getFullList({ expand: 'boxing, mma, kickboxing_woman, kickboxing, grapling' });
  //     return setServiceFightData(fightService);
  //   } catch (error) {
  //     return console.log('Ошибка:', error);
  //   } finally {
  //     setLoadingService(false);
  //   }
  // };

  // ручка получения тарифов танцев
  // const handleGetDanceTariffs = async () => {
  //   try {
  //     setLoadingService(true);
  //     const danceService = await api
  //       .collection('dance')
  //       .getFullList({ expand: 'workout' });
  //     return setServiceDanceData(danceService);
  //   } catch (error) {
  //     return console.log('Ошибка:', error);
  //   } finally {
  //     setLoadingService(false);
  //   }
  // };

  // ручка получения тарифов массажа и аквазоны
  const handleGetMassageTariffs = async () => {
    try {
      setLoadingService(true);
      const massage = await api
        .collection('aquazone_massage_cryosauna')
        .getFullList();
      return setServiceMassage(massage);
    } catch (error) {
      return console.log('Ошибка:', error);
    } finally {
      setLoadingService(false);
    }
  };

  // ручка получения прочих тарифов
  const handleGetOtherTariffs = async () => {
    try {
      setLoadingService(true);
      const otherService = await api
        .collection('other')
        .getFullList({ expand: 'solarium, infraredsauna' });
      return setServiceOtherData(otherService);
    } catch (error) {
      return console.log('Ошибка:', error);
    } finally {
      setLoadingService(false);
    }
  };

  // ручка получения тренров
  const handleGetMembers = async () => {
    try {
      setLoadingTrainers(true);
      const members = await api
        .collection('instructors')
        .getFullList();
      return setMembersData(members);
    } catch (error) {
      return console.log('Ошибка:', error);
    } finally {
      setLoadingTrainers(false);
    }
  };

  // ручка получения расписания танцев и фитнес направления
  const handleGetScheduleDance = async () => {
    try {
      setLoadingSchedule(true);
      const schedule = await api
        .collection('schedule_dance')
        .getFullList();
      return setScheduleDanceData(schedule);
    } catch (error) {
      return console.log('Ошибка:', error);
    } finally {
      setLoadingSchedule(false);
    }
  };

  // ручка получения расписаний по единоборству
  const handleGetScheduleFight = async () => {
    try {
      setLoadingSchedule(true);
      const schedule = await api
        .collection('schedule_fight')
        .getFullList();
      return setScheduleFightData(schedule);
    } catch (error) {
      return console.log('Ошибка:', error);
    } finally {
      setLoadingSchedule(false);
    }
  };

  // ручка получения инфы по специальному предложению, для модалки в gym
  const handleGetOfferGym = async () => {
    try {
      const offer = await api
        .collection('offer')
        .getFullList();
      return setOfferGym(offer);
    } catch (error) {
      return console.log('Ошибка:', error);
    }
  };

  // получение контактов
  useEffect(() => {
    handleGetContactsData();
  }, []);

  // получение инфы слайера
  useEffect(() => {
    handleGetSliderData();
  }, []);

  // получение всех тарифов
  useEffect(() => {
    handleGetGymTariffs();
    // handleGetFightTariffs();
    // handleGetDanceTariffs();
    handleGetMassageTariffs();
    handleGetOtherTariffs();
  }, []);

  // получение расписаний
  useEffect(() => {
    handleGetScheduleDance();
    handleGetScheduleFight();
  }, []);

  // получение тренеров
  useEffect(() => {
    handleGetMembers();
  }, []);

  // получение данных предложения тренажерного зала
  useEffect(() => {
    handleGetOfferGym();
  }, []);

  const context = useMemo(() => ({
    contactsData, isLoadingService, isLoadingTrainers, isLoadingSchedule, isLoadingSlider,
  }), [contactsData, isLoadingService, isLoadingTrainers, isLoadingSchedule, isLoadingSlider]);

  return (
    <AppContext.Provider value={context}>
      <Routes>
        <Route
          path="/"
          element={<MainPage sliderData={sliderData} />}
        />
        <Route
          path="/schedule-fight"
          element={<ScheduleFightPage scheduleFightData={scheduleFightData} />}
        />
        <Route
          path="/schedule-dance"
          element={<ScheduleDancePage scheduleDanceData={scheduleDanceData} />}
        />
        <Route
          path="/gym"
          element={<ServiceGymPage gymData={serviceGymData} offerGymData={offerGym} />}
        />
        {/* <Route
          path="/fighting-art"
          element={<ServiceFightPage fightData={serviceFightData} />}
        />
        <Route
          path="/dance"
          element={<ServiceDancePage danceData={serviceDanceData} />}
        /> */}
        <Route
          path="/massage"
          element={<ServiceMassagePage massageData={serviceMassage} otherData={serviceOtherData} />}
        />
        <Route
          path="/team"
          element={<TeamPage membersData={membersData} />}
        />
        <Route
          path="*"
          element={<Navigate to="/" replace />}
        />
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
