import { useContext } from 'react';

import { AppContext } from '../../../context/AppContext';

import fakeArray from '../../../utils/fakeArray';

import ScheduleItem from '../ScheduleItem';
import TitleWrapper from '../../TitleWrapper';

function ScheduleFight({ scheduleFightData }) {
  const { isLoadingSchedule } = useContext(AppContext);

  return (
    <main className="main">
      <TitleWrapper
        title="РАСПИСАНИЕ: ЗАЛА ЕДИНОБОРСТВ"
        link="/#schedule-list"
      />
      <section className="schedule__container accordion">
        <div className="schedule__inner">
          {
              // проходимся по массиву дней расписания и рендорим на страницу
              isLoadingSchedule ? fakeArray.map((fake) => <ScheduleItem key={fake.id} />)
                : scheduleFightData?.map((item) => (
                  <ScheduleItem key={item.id} dayName={item.day} dayList={item.dayList} />
                ))
          }
        </div>
      </section>
    </main>
  );
}

export default ScheduleFight;
