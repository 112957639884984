import { useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

function PopupSchedule({ opened, close }) {
  const onClickOutsideRef = useRef();

  // слушатель закрытия модалки по esc
  useEffect(() => {
    // проверяем открыта ли модалка, если происходит событие по esc, закрываем
    const onCloseToEsc = (e) => opened && e.key === 'Escape' && close();

    window.addEventListener('keyup', onCloseToEsc);
    return () => window.removeEventListener('keyup', onCloseToEsc);
  }, [close, opened]);

  // слушатель закрытия по заднему слою
  useEffect(() => {
    // проверяем открыта ли модалка, если происходит за пределами ее, закрываем
    const onClickOutside = (e) => opened
    && !onClickOutsideRef.current.contains(e.target)
    && close();

    document.addEventListener('mouseup', onClickOutside);
    return () => document.removeEventListener('mouseup', onClickOutside);
  }, [close, opened]);

  return (
    <section
      className={`popup__shedule overlay ${opened && 'overlay_active'}`}
    >
      <div
        className="popup__shedule-wrapper popup__modal"
        ref={onClickOutsideRef}
      >
        <button
          className="button button--close"
          type="button"
          aria-label="Close"
          onClick={close}
        />
        <h2 className="popup__shedule-title">ПОСМОТРЕТЬ РАСПИСАНИЕ</h2>
        <NavLink
          to="/schedule-fight"
          className="button button--schedule"
        >
          Боевые искусства
        </NavLink>
        <NavLink
          to="/schedule-dance"
          className="button button--schedule"
        >
          Танцы и фитнес-направления
        </NavLink>
      </div>
    </section>
  );
}

export default PopupSchedule;
