import { useState } from 'react';

import Seo from '../components/Seo';
import Header from '../components/Header';
import Team from '../components/Team';
import Footer from '../components/Footer';
import PopupMember from '../components/PopupMember';

function TeamPage({ membersData }) {
  const [popup, setPopup] = useState(false);
  const [memberData, setMemberData] = useState({}); // стейт приемки инфы

  // функция открытия модалки
  function handleOpenPopup(data) {
    setMemberData(data);
    setPopup(true);
  }

  // функция закрытия модалки
  function handleClosePopup() {
    setMemberData({});
    setPopup(false);
  }

  return (
    <div className="page__body">
      <Seo
        title="Спортивный клуб Hammer | Наша команда"
        description="Тренерский состав спортивного клуба Hammer - профессионально занимающиеся спортсмены 🏆, постоянно совершенствуют свою квалификацию и поддерживают высокий уровень профессионализма. Именно эти люди обеспечивают Вам надежную поддержку и опору."
        keywords="персональный тренер в Самаре, спортивный клуб, тренажерный зал, вакансия тренера"
      />
      <Header />
      <Team
        handleOpenPopup={handleOpenPopup}
        membersData={membersData}
      />
      <Footer />
      <PopupMember
        popupState={popup}
        close={handleClosePopup}
        memberData={memberData}
      />
    </div>
  );
}

export default TeamPage;
