function TariffWrapper({ title, singleGrid, children }) {
  // singleGrid булевое, принемается пропсом если нужно сделать в 1 линию блок тарифов
  return (
    <div className="grid__wrapper">
      <h2>{ title }</h2>
      { singleGrid && children }
      {
        !singleGrid && (
          <ul className="grid__list">
            { children }
          </ul>
        )
      }
    </div>
  );
}

export default TariffWrapper;
