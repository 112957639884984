const sliderLoaderSize = {
  desktopWidth: 295,
  desktopHeight: 360,
  mobileWidth: 250,
  mobileHeight: 310,
};

const sliderDesktopSetting = {
  circle: {
    cx: 148,
    cy: 125,
    r: 125,
  },
  text: {
    width: 250,
    height: 20,
    x: 23,
    y: 290,
    rx: 0,
    ry: 0,
  },
};

const sliderMobileSetting = {
  circle: {
    cx: 125,
    cy: 115,
    r: 115,
  },
  text: {
    width: 200,
    height: 20,
    x: 25,
    y: 270,
    rx: 0,
    ry: 0,
  },
};

export { sliderLoaderSize, sliderDesktopSetting, sliderMobileSetting };
