import { useContext } from 'react';

import { AppContext } from '../../../context/AppContext';

import fakeArray from '../../../utils/fakeArray';

import ScheduleItem from '../ScheduleItem';
import TitleWrapper from '../../TitleWrapper';

function ScheduleDance({ scheduleDanceData }) {
  const { contactsData, isLoadingSchedule } = useContext(AppContext);

  return (
    <main className="main">
      <TitleWrapper
        title="РАСПИСАНИЕ: ТАНЦЫ И ФИТНЕС - НАПРАВЛЕНИЯ"
        link="/#schedule-list"
      >
        <p>
          По всем направлениям предусмотрены групповые и индивидуальные тренировки,
          которые формируются по предварительной записи.
          Вопросы по расписанию и стоимости уточняйте по телефону
          <br />
          <a href="tel:+79608081827">{contactsData[8]?.contact}</a>
        </p>
      </TitleWrapper>
      <section className="schedule__container accordion">
        <div className="schedule__inner">
          {
              // проходимся по массиву дней расписания и рендорим на страницу
              isLoadingSchedule ? fakeArray.map((fake) => <ScheduleItem key={fake.id} />)
                : scheduleDanceData?.map((item) => (
                  <ScheduleItem key={item.id} dayName={item.day} dayList={item.dayList} />
                ))
          }
        </div>
      </section>
    </main>
  );
}

export default ScheduleDance;
