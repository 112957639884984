import Seo from '../components/Seo';
import Header from '../components/Header';
import ScheduleDance from '../components/Schedule/ScheduleDance';
import Footer from '../components/Footer';

function ScheduleDancePage({ scheduleDanceData }) {
  return (
    <div className="page__body">
      <Seo
        title="Спортивный клуб Hammer | Расписание занятий по танцам и фитнес-направлениям"
        description="Секции по танцам 💃 и фитнес-направлениям 🧘‍♀️ в Самаре . В данном разделе можно найти актуальное расписание занятий по танцам и фитнес-направлениям в спортивном клубе Hammer."
        keywords="фитнес-направления, секции танцев в Самаре, стретчинг в Самаре, тренажерный зал, фитнес-клуб"
      />
      <Header />
      <ScheduleDance scheduleDanceData={scheduleDanceData} />
      <Footer />
    </div>
  );
}

export default ScheduleDancePage;
