function IconMassage() {
  return (
    <svg
      viewBox="0 0 84 84"
      width={84}
      height={84}
      fill="none"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84 42c0 23.196-18.804 42-42 42S0 65.196 0 42 18.804 0 42 0s42 18.804 42 42z"
        fill="#C7E80A"
      />
      <path
        d="M73.6 49.126L71.203 47M81 53l-3-1M47.22 8.713l-2.443-1.426M38 5l-3-1m19.3 8.927l2.261 1.88m4.459 5.803l1.62 2.454M66 29l1.07 2.739m1 7.261L68 41.94"
        stroke="#2D3807"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M43.5 26c2.485 0 4.5-2.239 4.5-5s-2.015-5-4.5-5-4.5 2.239-4.5 5 2.015 5 4.5 5zM16 51a5 5 0 100-10 5 5 0 000 10z"
        fill="#2D3807"
      />
      <path
        d="M47.123 26.465c-5.427 3.598-13.262 10.63-14.99 13.519-1.594 2.663 11.687-3.213 13.773-4.148a.411.411 0 01.48.106c1.715 1.93 2.914 4.019 3.622 6.744a.423.423 0 00.41.314h9.09c.092 0 .184-.026.243-.095 1.875-2.205-7.29-19.98-12.628-16.44z"
        fill="#2D3807"
        stroke="#2D3807"
        strokeLinecap="round"
      />
      <path
        d="M9 54h62"
        stroke="#2D3807"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M59.955 44.602c-3.611-.85-34.943-.755-36.047 0-1.105.756-.875 6.146-.875 8.234s-4.614 6.825-2.823 7.995C22 62 26.364 58.022 27.292 56.57c.726-1.134.814-3.468.818-4.43a.428.428 0 01.439-.42c4.308.074 38.638.643 40.196-.008 1.662-.694-5.18-6.26-8.79-7.11z"
        fill="#2D3807"
        stroke="#C7E80A"
        strokeLinecap="round"
      />
      <path
        d="M60.533 57h-5.067c-.258 0-.468.19-.466.434.023 2.36.253 13.566 2.584 13.566 2.323 0 3.243-11.128 3.415-13.542.018-.253-.196-.458-.466-.458z"
        fill="#2D3807"
        stroke="#2D3807"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default IconMassage;
