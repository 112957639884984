import { useRef, useEffect } from 'react';
import DOMPurify from 'dompurify';

function PopupOffer({
  opened, close, popupState, offerData,
}) {
  const onClickOutsideRef = useRef();

  // слушатель закрытия модалки по esc
  useEffect(() => {
    // проверяем открыта ли модалка, если происходит событие по esc, закрываем
    const onCloseToEsc = (e) => popupState && e.key === 'Escape' && close();

    window.addEventListener('keyup', onCloseToEsc);
    return () => window.removeEventListener('keyup', onCloseToEsc);
  }, [close, popupState]);

  // слушатель закрытия модалки по заднему слою
  useEffect(() => {
    // проверяем открыта ли модалка, если происходит за пределами ее, закрываем
    const onClickOutside = (e) => popupState
    && !onClickOutsideRef.current.contains(e.target)
    && close();

    document.addEventListener('mouseup', onClickOutside);
    return () => document.removeEventListener('mouseup', onClickOutside);
  }, [close, popupState]);

  return (
    <section
      className={`popup popup__offer overlay ${opened && 'overlay_active'}`}
    >
      <div
        className="popup__offer-wrapper"
        ref={onClickOutsideRef}
      >
        <button
          className="button button--close"
          type="button"
          aria-label="Close"
          onClick={close}
        />
        <div
          className="popup__offer-info"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(offerData[0]?.text) }}
        />
      </div>
    </section>
  );
}

export default PopupOffer;
